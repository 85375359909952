<script lang="ts" setup>
import type { Social } from "~/types";

const { t } = useT();

const submitClicked = ref(false);
const errorMsgs = {
	notValid: t("Form not valid"),
	notCompleted: t("Must be completed")
};

const { open, close } = useTaoModals();

const {
	email,
	password,
	signupLoading,
	isFullButtonEnabled,
	isEmailShowError,
	isEmailTouched,
	error,
	errorMessage,
	isPasswordShowError,
	isPasswordTouched,
	acceptRestrictedStates,
	isAcceptRestrictedStatesError,
	acceptTerms,
	isAcceptTermsError,
	socialsOtp,
	registrationType,
	resetError,
	handleSubmit
} = useSignup({ errorMsgs, open, close, shouldCheckRegisterType: true });

const { refresh } = useHomePage();

const submitForm = async () => {
	await handleSubmit();
	refresh();
};

const handleClickBtn = (socialHref: string, socialName: Social["name"]) => {
	if (registrationType.value) {
		open("LazyOModalReRegistration", { newType: socialName });
		return;
	}

	registrationType.value = socialName;
	window.open(socialHref, "_blank");

	dispatchGAEvent({
		event: "click_button",
		button_name: socialName,
		location: "registration_window"
	});
};
</script>

<template>
	<div class="open-registration login">
		<form @submit.prevent="submitForm()">
			<MBanner
				class="offer"
				:image="'/nuxt-img/auth/open-registration-first.webp'"
				:image-mobile="'/nuxt-img/auth/open-registration-first-mob.png'"
				:imageMobileWidth="1536"
				:imageMobileHeight="1200"
				:second-image="'/nuxt-img/auth/open-registration-second.webp'"
				:second-image-mobile="'/nuxt-img/auth/open-registration-first-mob.png'"
				:secondImageWidth="850"
			>
				<template #default>
					<AText type="h6 h4-md" class="open-registration__header">
						<i18n-t keypath="get {key} and win big!">
							<template #key>
								<span class="color-primary">{{ t("free coins every day") }}</span>
							</template>
						</i18n-t>
					</AText>
				</template>
				<template #description>
					<div class="form-profile form-profile__inputs">
						<div class="form-profile__input">
							<label class="form-label">
								<AAnimationSlide
									mode="left-to-right"
									class="field-name"
									iterationCount="3"
									:duration="0.2"
									:distance="5"
									:active="isEmailShowError"
								>
									<AText type="x-small" class="label mb-1">{{ t("Email") }}</AText>
								</AAnimationSlide>
								<MEmailInput
									v-model="email"
									:invalid="isEmailShowError"
									autocomplete="username"
									class="input-email"
									location-tid="footer-auth-email"
									:placeholder="t('Your Email')"
									@blur="isEmailTouched = true"
									@input="resetError"
								/>
							</label>
							<div class="form-error__wrapper">
								<AAnimationSlide v-if="isEmailShowError" :active="isEmailShowError">
									<AText type="x-small" class="input-error" data-tid="footer-auth-email-validation">{{
										t("Please provide a valid email")
									}}</AText>
								</AAnimationSlide>
							</div>
						</div>

						<div class="form-profile__input">
							<label class="form-label">
								<AAnimationSlide
									mode="left-to-right"
									class="field-name"
									iterationCount="3"
									:duration="0.2"
									:distance="5"
									:active="isPasswordShowError"
								>
									<AText type="x-small" class="label mb-1">{{ t("Password") }}</AText>
								</AAnimationSlide>
								<MPasswordInput
									v-model="password"
									class="input-password"
									location-tid="footer-auth-password"
									:invalid="isPasswordShowError"
									autocomplete="current-password"
									:placeholder="t('Enter password')"
									@blur="isPasswordTouched = true"
								/>
							</label>
							<div class="form-error__wrapper">
								<AAnimationSlide v-if="isPasswordShowError" :active="isPasswordShowError">
									<AText type="x-small" class="input-error" data-tid="footer-auth-password-validation">{{
										t("Password must be at least 6 characters long")
									}}</AText>
								</AAnimationSlide>
							</div>
						</div>
					</div>
					<div class="form-profile form-profile__checkboxes">
						<MCheckbox
							v-model="acceptRestrictedStates"
							:invalid="isAcceptRestrictedStatesError"
							class="form-checkbox mb-2 mb-md-3"
							data-tid="footer-auth-age-confirm-chk"
						>
							<AText type="x-small" class="checkbox-text">
								{{ t("I am at least 18 years old and not a resident of the") }}
								<NuxtLink
									to="/page/agreement/#restricted"
									class="checkbox-link"
									target="_blank"
									data-tid="footer-auth-age-rules-link"
								>
									{{ t("Restricted states") }}
								</NuxtLink>
							</AText>
						</MCheckbox>
						<MCheckbox
							v-model="acceptTerms"
							:invalid="isAcceptTermsError"
							class="form-checkbox"
							data-tid="footer-auth-terms-confirm-chk"
						>
							<AText type="x-small" class="checkbox-text">
								<i18n-t
									keypath="I accept the TaoFortune the {key} and {key1} (including, for the California residents the {key2} )."
								>
									<template #key>
										<NuxtLink
											to="/page/agreement"
											class="checkbox-link"
											target="_blank"
											data-tid="footer-auth-agreement-rules-link"
										>
											{{ t("Terms of Use") }}
										</NuxtLink>
									</template>
									<template #key1>
										<NuxtLink
											to="/page/privacy"
											class="checkbox-link"
											target="_blank"
											data-tid="footer-auth-privacy-rules-link"
										>
											{{ t("Privacy Policy") }}
										</NuxtLink>
									</template>
									<template #key2>
										<NuxtLink
											to="/page/california-privacy-notice"
											class="checkbox-link"
											target="_blank"
											data-tid="footer-auth-california-rules-link"
										>
											{{ t("California Privacy Notice") }}
										</NuxtLink>
									</template>
								</i18n-t>
							</AText>
						</MCheckbox>
						<div class="form-error__wrapper">
							<AAnimationSlide v-if="error === 'agree'" :active="error === 'agree'" :distance="4">
								<AText type="x-small" class="input-error" data-tid="footer-auth-rules-disagree-error">{{
									errorMessage
								}}</AText>
							</AAnimationSlide>
						</div>
					</div>
				</template>
				<template #actions>
					<div class="form-profile__buttons d-flex flex-column flex-md-row align-items-center gap-3 gap-md-4">
						<AButton
							class="submit-button"
							type="submit"
							variant="primary"
							size="lg"
							data-tid="footer-auth-create-account-btn"
							:modifiers="!isFullButtonEnabled ? ['disabled'] : []"
							@click="submitClicked = true"
						>
							<NuxtIcon v-if="signupLoading" name="28/spinner" class="spinner" />
							<template v-else>{{ t("join now") }}</template>
						</AButton>
						<div v-if="socialsOtp.length" class="socials__buttons d-flex flex-row gap-4 align-items-center">
							<AText type="h6">{{ t("or") }}</AText>
							<AButton
								v-for="social in socialsOtp"
								:key="social.id"
								class="btn__socials"
								variant="neutral"
								size="lg"
								:data-tid="`auth-footer-${social.name.toLowerCase()}-btn`"
								@click.stop="handleClickBtn(social.href, social.name)"
							>
								<NuxtIcon
									class="btn__socials-icon icon-size-24"
									:filled="true"
									:name="`24/${social.id === 'fb' ? 'fb-colored' : 'google-colored'}`"
								/>
							</AButton>
						</div>
					</div>
				</template>
			</MBanner>
		</form>
	</div>
</template>
<style lang="scss" scoped>
.app-banner {
	height: 300px;

	@include media-breakpoint-down(md) {
		height: 600px;
	}

	&:deep(.app-banner__title) {
		@include media-breakpoint-down(md) {
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&:deep(.app-banner__content) {
		justify-content: space-between;
		gap: 8px;
		padding-top: 24px;
		padding-bottom: 24px;

		@include media-breakpoint-down(md) {
			justify-content: flex-start;
			padding-top: 16px;
			padding-bottom: 16px;
		}

		@include media-breakpoint-down(sm) {
			gap: 4px;
		}
	}

	&:deep(.app-banner__desc) {
		margin: 0;
		text-align: left;
	}

	&:deep(.app-banner__img--two) {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&:deep(.app-banner__actions) {
		@include media-breakpoint-down(md) {
			width: 100%;
			align-items: stretch;
		}
	}
}
.btn__socials {
	min-width: 48px;
}

.checkbox-link {
	color: var(--primary);
	text-decoration: underline;
	text-decoration-color: var(--primary) !important;
	&:hover {
		text-decoration: none !important;
	}
}

.submit-button {
	min-width: 240px;

	@include media-breakpoint-down(md) {
		width: 100%;
	}

	.spinner {
		font-size: 40px;
	}
}

.form-profile {
	width: 574px;
	@include media-breakpoint-down(md) {
		width: 100%;
	}
	&__inputs {
		display: flex;
		gap: gutter(2.5);
		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: center;
			gap: 0;
			padding-bottom: 0;
		}
	}

	&__input {
		width: calc(50% - 10px);
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.form-profile__footer {
	width: 574px;
}

.form-error__wrapper {
	height: 16px;

	@include media-breakpoint-down(md) {
		height: 12px;
		line-height: 12px;
	}
}

.input-email,
.input-password {
	--a-input-padding: 10px;
	--a-input-line-height: 20px;
	--a-input-font-size: 14px;
	--a-input-color: var(--gray-950);
	--a-input-group-color: var(--neutral-400);
	--a-input-group-border: var(--neutral-400);
	--a-input-group-background: var(--neutral);
	--a-input-group-border-radius: 4px;
	--a-input-group-invalid-color: var(--danger);
	--a-input-group-invalid-border: var(--danger);
	--a-input-group-border-width: 1px;
	--a-input-group-hover-border: var(--secondary);
	--a-input-placeholder-color: var(--neutral-400);

	&:deep(.password-icon) {
		color: var(--gray-600);
		margin-right: 10px;

		svg {
			fill: currentColor;

			path {
				fill: currentColor;
			}
		}
	}
}

.form-profile__checkboxes {
	--a-checkbox-size: 16px;
	--a-checkbox-background: var(--neutral);
	--a-checkbox-checked-hover-background: var(--a-checkbox-background);
	--a-checkbox-checked-background: var(--neutral);
	--a-checkbox-border: var(--neutral-400);
	--a-checkbox-checked-border: var(--a-checkbox-border);
	--a-checkbox-after-width: 8px;
	--a-checkbox-after-height: 8px;
	--a-checkbox-after-transform: translate(-50%, -50%);
	--a-checkbox-checked-transform: translate(-50%, -50%);
	--a-checkbox-checked-after-transform: translate(-50%, -50%);
	--a-checkbox-after-border-bottom: 0;
	--a-checkbox-after-border-right: 0;
	--a-checkbox-after-left: 50%;

	label {
		line-height: 16px;
		max-width: 545px;
	}
}

.input-error {
	color: var(--danger);
	margin-top: 4px;
}
</style>
